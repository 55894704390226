import $ from 'jquery'
import { Controller } from "stimulus"
import Highcharts from 'highcharts'

export default class extends Controller {
  static targets = ["chart"]

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      this.init()
      this.load()
    }
  }

  init() {
    let params = $(this.chartTarget).data()
    let dataOnLoading = [
      [params.start, parseInt(params.startBalance)],
      [params.end, parseInt(params.endBalance)]
    ]
    Highcharts.chart(this.chartTarget, {
      title: { text: 'Total Balance' },
      xAxis: {
        type: 'datetime'
      },
      yAxis: {
        title: { enabled: false }
      },
      tooltip: {
        shared: true
      },
      credits: { enabled: false },
      series: [
        {
          data: dataOnLoading,
          name: 'amount',
          title: { enabled: false },
          marker: { enabled: false }
        }
      ]
    })
  }

  load() {
    let chart = Highcharts.charts.pop()
    chart.showLoading('Loading...')
    let data = $(this.chartTarget).data()
    $.get(data.url).then(response => {
      chart.series[0].setData(response.data)
      chart.hideLoading()
    })
  }
}
