/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "core-js/stable";
import "regenerator-runtime/runtime";

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import { summaryInitChart, summaryLoadData } from 'chart/summary'
import moment from 'moment-timezone'
import Highcharts from 'highcharts'

Highcharts.setOptions({
  time: {
      getTimezoneOffset: function (timestamp) {
        var zone = 'Europe/Kiev',
            timezoneOffset = -moment.tz(timestamp, zone).utcOffset();

        return timezoneOffset;
      },
  }
});

const application = Application.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))


document.addEventListener('turbolinks:load', () => {
  // if($('#container-rates').length) {
  //   ratesInitChart()
  //   ratesLoadData($('#container-rates-params').data(), $('#container-rates').data('urls').split(','))
  // }
  // if($('#container-balance').length) {
  //   balanceInitChart('container-balance')
  //   balanceLoadData('container-balance')
  // }
  if($('#container_summary_1').length) {
    summaryInitChart('container_summary_1')
    summaryLoadData('container_summary_1')
    summaryInitChart('container_summary_2')
    summaryLoadData('container_summary_2')
    summaryInitChart('container_summary_3')
    summaryLoadData('container_summary_3')

  }
})

require("trix")
require("@rails/actiontext")