import Highcharts from 'highcharts'

export function summaryInitChart(id) {
  Highcharts.chart(id, {
    chart: {
        type: 'column'
    },
    credits: {
        enabled: false,
    },
    title: {
        text: ''
    },
    xAxis: {
        categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
        ],
        crosshair: true
    },
    yAxis: {
        min: 0,
        title: {
            text: 'amount'
        }
    },
    tooltip: {
        shared: true
    },
    series: [
      { name: 'income' },
      { name: 'outcome' },
      { type: 'line', name: 'avg income', dashStyle: 'Dot', marker: { enabled: false } },
      { type: 'line', name: 'avg outcome', dashStyle: 'Dot', marker: { enabled: false } }
    ]
  });
}

export function summaryLoadData(container) {
  let chart = Highcharts.charts.pop()
  chart.showLoading('Loading...')
  let data = $(`#${container}`).data()
  $.get(data.url, { year: data.year }).then((response) => {
    chart.setTitle({ text: data.year })
    chart.series[0].setData(response.income)
    chart.series[1].setData(response.outcome)
    chart.series[2].setData(response.avg.income)
    chart.series[3].setData(response.avg.outcome)
    chart.hideLoading()
  })
}
