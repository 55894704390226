import { Controller } from "stimulus"
import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more'
import $ from 'jquery'

HighchartsMore(Highcharts)

var params, urls

export default class extends Controller {
  static targets = [ "chart" ]

  connect() {
    if (document.documentElement.hasAttribute("data-turbolinks-preview")) {
      return
    }

    this.init()
    params = {
      start: $(this.chartTarget).data('start'),
      end: $(this.chartTarget).data('end'),
      from: $(this.chartTarget).data('from'),
      to: $(this.chartTarget).data('to'),
    }
    urls = $(this.chartTarget).data('urls').split(',')
    this.load()
  }

  init() {
    Highcharts.chart(this.chartTarget, {
      chart: { type: 'arearange' },
      title: { text: 'Exchange rates' },
      xAxis: {
        type: 'datetime'
      },
      tooltip: {
        shared: true
      },
      credits: { enabled: false }
    })
  }

  load() {
    let chart = Highcharts.charts[0]
    chart.showLoading('Loading...')
    while(chart.series.length) {
      chart.series[0].remove()
    }

    for(var j in urls) {
      $.get(urls[j], params, (response) => {
        for(var i in response.data) {
          chart.addSeries({
            data: response.data[i].data,
            name: response.data[i].name,
            fillOpacity: 0.3,
            marker: { enabled: false }
          })
        }
        chart.hideLoading()
      })
    }
  }
}
