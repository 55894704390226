import { Controller } from "stimulus"
import 'selectivity/styles/selectivity-jquery.css'
import $ from 'jquery'
import 'selectivity/jquery'

export default class extends Controller {
  static targets = ["parentSelect"]

  connect() {
    var $item = $(this.parentSelectTarget)
    $item.selectivity({
      allowClear: true,
      placeholder: 'No Parent Selected',
      data: $item.data('items'),
      ajax: {
        url: '/notes.json',
        minimumInputLength: 3,
        dataType: 'json',
        quietMillis: 250,
        params(term, offset) {
          return {
            q: term
          }
        },
        fetch(url, init, queryOptions) {
          return $.ajax(url).then(data => {
            return {
              results: $.map(data.items, item => {
                return {
                  id: item.id,
                  text: item.title,
                  description: item.description
                }
              })
            }
          })
        }
      },
      templates: {
        resultItem: (item) => {
          return  '<div class="selectivity-result-item" data-item-id="' + item.id + '">' + (item.text) + ' <small>(' + item.description + ')</small></div>'
        }
      }
    })
    $item.on('change', (data) => {
      $($item.data('parent-id')).val($item.selectivity('value'))
    })
  }
}
